.login_banner {
  background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/login_bg.svg?updatedAt=1683729262508");
  background-size: cover;
  background-repeat: no-repeat;
  height: 315px;
  border-radius: 11.1538px;
  margin: 10px;
  padding: 20px;
}
.logo_im_mobile{
  display: none;
}
.login_content {
  text-align: center;
  padding-top: 60px;
}

.login_content h3 {
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  font-size: 32px;
}

.login_content p {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

.logo_im {
  /*background: #ffffff;*/
  border-radius: 10px;
  width: 210px;
  color: #000;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
}

.login-tab-content {
  position: relative;
  top: -80px;
}

.login-board-box {
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: rgba(26, 26, 26, 0.2);

  border: 1px solid rgba(26, 26, 26, 0.2);
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 0px 0px 20px 20px;
  width: 452px;
  margin: 0 auto;
  padding: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.login-modal {
  list-style: none;
  text-align: center;
  padding: 0px;
  width: 452px;
  margin: 0 auto;
}

.login-modal li {
  padding: 13px;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #d0004b;
  border-radius: 12px 12px 0px 0px;
}

.admin-login h4 {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 25px;
  color: #1a1a1a;
}

.admin-login a {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  display: block;
  padding-top: 30px;
}

.TimerBody {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ResendLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #d0004b;
  cursor: pointer;
}

.otp-bx {
  display: flex;
  justify-content: space-between;
}

.otp-input {
  width: 70px;
  height: 70px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
}

.otp-err {
  border-color: #FE1717;
}

.otp-input:focus {
  border-color: #d0004b;
}

input::-webkit-inner-spin-button {
  display: none;
}
.register-now{
  all: unset;
  display: flex;
  color: #1a1a1a;
  justify-content: center;
}
@media only screen and (max-width : 767px) {
  .login_banner {
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
  }

  .login_content h3 {
    font-size: 20px;
  }

  .login_content p {
    font-size: 12px;
  }
  .login-board-box{
    width: 100%;
    padding: 20px;
  }
  .login-modal{
    width: 100%;
  }
  .login-tab-content {
    top: -115px;
    padding: 9px;
}
.login-modal li{
  font-size: 17px;
}
.mobile-view{
  display: none;
}
.logo_im_mobile{
  display: block;
  background-color: #ffffff;
  text-align: center;
  font-weight: 700;
    padding: 8px;
}
.otp-input {
  width: 52px;
    height: 52px;
    margin-bottom: 8px;
}
.admin-login p{
  font-size: 12px;
}

}
