.file-input-container {
    display: flex;
    align-items: center;
  }
  
  .custom-file-input {
    display: flex;
    align-items: center;
    /* bosrder: 1px solid #ddd; */
    padding: 8px 12px;
    /* border-radius: 8px; */
    gap: 8px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .file-input-label {
    padding: 5px 10px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 7px;
  }
  .file-input-label:hover{
    background-color: #e1dddd;
  }
  .file-chosen {
    color: #555;
    font-size: 14px;
    margin-left: 8px;
  }
  
  .add-file-btn {
    position: absolute;
    background: none;
    border: 2px solid #d32f2f; /* Red border color */
    border-radius: 50%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d32f2f; /* Red color */
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
    right: 20px;
    top: -4px;
  }
  
  .add-file-btn:hover {
    background-color: #f8e1e1;
  }
  .file-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    
  }
  
  .file-pill {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background-color: #e0f7fa;
    border-radius: 16px;
    font-size: 14px;
    color: #00796b;
    border: 1px solid #b2dfdb;
    gap: 6px;
    /* width: 100px; */
  }
  
  .file-name {
    margin-right: 4px;
  }
  
  .remove-icon {
    font-size: 14px;
    color: #d32f2f;
    cursor: pointer;
  }
  
  .remove-icon:hover {
    color: #b71c1c;
  }