.dropdown-container {
    position: relative;
    /* width: 200px; Adjust the width as needed */
    border: none;
  }
  
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    cursor: pointer;
    background-color: #fff;
    font-size: 18px;
    font-weight: 400;
    color: #1a1a1a;

  }
  
  .dropdown-list {
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
    z-index: 1000;
  }
  
  .dropdown-list-item {
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
  }
  
  .dropdown-list-item:hover {
    background-color: #f0f0f0;
  }
  
  .arrow {
    /* font-size: 10px; */
    margin-right: 15px;
  }
  .dropdown-list::-webkit-scrollbar {
    width: 6px;
    margin-right: 10px;
  }
  
  .dropdown-list::-webkit-scrollbar-thumb {
    background-color: rgba(145, 151, 179, 1);
    border-radius: 8px; 
    margin-right: 10px;
    height: 50px;
  }
  .dropdown-search{
    border: none;
    outline: none;
  }