.upload-container{
    background-color: white;
    border-radius: 30px;
   margin: 0 40px;
    height: 80vh;
    overflow: hidden;
    padding: 20px;
    margin-bottom: 20px;
}
.sample-file{
    display: flex;
    align-items: center;
    gap: 16px;
    color: #D0004B;
    font-weight: 600;
}
.sample-file-2{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #D0004B;
    font-weight: 600;
    justify-content: flex-end;
    padding-right: 40px;
    /* margin-bottom: 34px; */
}
.upload-container-inner{
    border-radius: 30px;
    border: 1px dashed rgba(160, 174, 192, 1);
    margin-top: 30px;
    height: 100% !important;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.upload-container-inner>label{
 color: black;
 font-weight: 600;
}
.left-tabs{
    margin-left: 40px;
    background-color: rgba(233, 233, 233, 1);
    display: flex;
    width: max-content;
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important;
}
.left-tabs>div{
    padding: 15px 40px;
}
.left-tabs>div:first-child{
    border-top-left-radius: 30px !important;
}
.left-tabs>div:last-child{
    border-top-right-radius: 30px !important;
}
.active-tab{
    background-color: #D0004B;
    color: white;
    font-weight: 600;
}
.inactive-tab{
    background-color: rgba(233, 233, 233, 1);
    color: rgba(126, 126, 126, 1);
}
.proceed-btn{
    background-color: #D0004B;
    font-weight: 600;
    color: white;
    height: 40px;
    min-width: 160px;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 800px;
    border: none;
    outline: none;
    margin-right: 40px;
    box-sizing: border-box;
    padding: 0 5px;
    font-size: clamp(10px, 4vw, 14px)
}
.proceed-btn-outlined{
    background-color: white;
    border: 2px solid #D0004B;
    color: #D0004B;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
}
.proceed-btn-outlined-disabled{
    border: 2px solid #7e7e7e;
    color: #7e7e7e;
}
.proceed-dialog{
    box-sizing: border-box;
    padding: 20px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.proceed-dialog>.black-cross{
    position: absolute;
    right: 0;
    top: 0;
}
.fix-errors-text{
    background-color: rgba(245, 245, 245, 1);
    margin: 0 40px;
    height: 62px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    gap: 10px;
}

.pm-pill{
 margin-top: 5px;
 padding: 1px 4px !important;
 border-radius: 4px;
 font-size: 13px;
 width: max-content;
}
.automatic-pill{
    border: 1px solid rgba(0, 172, 79, 1);
    color: rgba(0, 172, 79, 1);
    background-color: rgba(229, 247, 237, 1);
   
}
.manual-pill{
    border: 1px solid rgba(0, 172, 79, 1);
    color: rgba(0, 172, 79, 1);
    background-color: rgba(237, 249, 209, 1);
}
.free-pill{
    border: 1px solid rgba(71, 99, 228, 1);
    color: rgba(71, 99, 228, 1);
    background-color: rgba(227, 242, 253, 1);
}

.sample-file-wrapper{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.sample-file,.sample-file-2{
    display: flex;
    width: max-content;
}