.product-card {
    /* max-width: 350px; */
    min-width: 200px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    position: relative;
    background: #fff;
    height: 400px;
  }
  .completed{
    font-size: 12px;
    color: #1a1a1a;
    display: flex;
    gap: 3px;
    align-items: center;
  }
  .product-card:hover{
    background-color: white;
    box-shadow: 0px 20px 32px 0px rgba(96, 97, 112, 0.24);

    /* box-shadow: 0px 2px 8px 0px rgba(40, 41, 61, 0.08); */

  }
  .image-container{
    padding: 15px;
    border-radius: 10px;
  }
  .product-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .card-content {
    padding: 15px;
    position: relative;
  }
  
  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .track-button {
    background-color: rgb(208, 0, 75, 0.08);
    color: #d63384;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    padding: 4px 10px;
    cursor: pointer;
    width: 'auto';
  }
  
  .track-button:hover {
    background-color: #e4b8bf;
  }
  
  /* .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 15px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    top: -4px;
    height: 25px;
    width: 25px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  } */
  
  /* input:checked + .slider {
    background-color: #4CAF50;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
   */
  .product-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  
  .product-description {
    font-size: 20px;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .product-description::first-letter{
    text-transform: capitalize;
  }
  .price-date {
    display: flex;
    gap: 10px;
    align-items: center;
    position: absolute;
    top: 140px;
  }
  .line{
    border: 0.5px solid rgba(26, 26, 26, 0.4);
    width: 1px;
    height: 20px;
  }
  .price {
    font-size: 18px;
   
  }
  
  .date {
    font-size: 14px;
    color: #999;
  }
  