.blog-card {
  background: #ffffff;
  border: 1px solid #eff0f7;
  border-radius: 12px;
  padding: 14px;
  height: 220px;
  position: relative;
}
.blog-card h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1a1a1a;
  word-wrap: break-word;
}
.blog-card-header{
  /* background-color: red; */
  display: flex;
  align-items: center;
  gap: 20px;
}
.blog-card-header img{
  border-radius: 50%;
  height: 80px !important;
  width: 80px !important;
}
.blog-card-header-content>h4{
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #1a1a1a;
  word-wrap: break-word;
}
.blog-card-menu{
  width: max-content;
  position: absolute;
  top: 15px;
  right: 5px;
}
.blog-card-header-content>div>p:nth-child(1){
  all: unset;
  color: #1A1A1A;
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
}
.blog-card-header-content>div>p:last-child{
  all: unset;
  color: #7E7E7E;
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
}
.blog-card-text{
  margin-top: 20px;
  overflow-wrap: break-word; /* Forces text to break at boundaries */
  word-wrap: break-word; /* Ensures compatibility with older browsers */
  white-space: normal;
  color: #1A1A1A;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
 /* overflow: hidden; */
}
.blog-card button {
  font-weight: 700;
  font-size: 14px;
  min-width: 100px;
  min-height: 32px;
}
.blog-card img {
  width: 100%;
}
.blog-profile {
  display: flex;
  align-items: center;
}
.blog-profile img {
  width: 34px;
  height: 34px;
  margin-right: 10px;
}
.blog-profile span {
  font-weight: 500;
  font-size: 14px;
  color: #7e7e7e;
}
.border-right {
  border-right: 1px solid #7e7e7e;
  height: 13px;
  margin: 4px 10px;
}
/* .blog-card-menu {
  position: relative;
} */

.menu-list {
  position: absolute;
  top: 30px; /* Adjust to position the dropdown */
  right: -10;
  background-color: white;
  border-radius: 8px; /* Smooth rounded corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for floating effect */
  z-index: 100;
  width: 150px; /* Adjust width */
  padding: 5px 0;
}

.menu-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-list li {
  padding: 10px 16px;
  cursor: pointer;
  font-size: 14px;
  color: black; /* Default text color */
  text-align: left;
}

.menu-list li:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
}


.menu-list li:first-child {
 /* Blue border around 'Edit Details' */
  border-radius: 4px;
  padding: 8px 14px; /* Adjust padding for consistency */
}

.blog-card:hover {
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 12px;
}
.details-card-blog {
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  background: #fff;
  padding: 60px 150px;
}
.details-card-blog h2 {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  color: #1a1a1a;
}
.input-boxx {
  height: 156px !important;
  background: #ffffff;
  width: 100%;
  display: block;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 8px;
}
.user_data h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #7e7e7e;
}
.user_data b {
  color: #000;
}
.user_data h6 {
  font-weight: 500;
  font-size: 12px;
}
.chart-box-proress {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  padding: 27px;
}
progress_bar {
  display: flex;
  justify-content: center;
}
.progress_bar label {
  font-size: 14px;
}
.progress_bar progress {
  height: 18px;
}
.progress_bar h6 {
  font-size: 14px;
}
.progress_skill {
  background: #d3edea;
  border-radius: 80px;

  width: 100%;
  height: 8px;
}
.skill_im {
  position: relative;
  top: -8px;
}
.skill1 {
  background: #00ac4f;
  border-radius: 80px;
  padding: 0;
  height: 10px;
  text-align: right;
}
.common-card {
  filter: drop-shadow(0px 1.99177px 7.96708px rgba(40, 41, 61, 0.08))
    drop-shadow(0px 19.9177px 31.8683px rgba(96, 97, 112, 0.24));
  height: 300px;
  border-radius: 30px;
  padding: 15px;
  background-color: #ffffff;
  margin: 20px 0px;
}

@media (max-width: 767px) {
  .details-card-blog h2 {
    font-size: 20px;
    line-height: 30px;
  
  }
 

}
