.progress-section {
    background-color: #D3EDEA80; /* Light green background */
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    
  }
  
  .progress-bar-container {
    margin: 20px 0;
  }
  
  .progress-bar {
    position: relative;
    height: 15px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    width: 8%; /* Adjust this value dynamically based on percentage */
    height: 100%;
    background-color: #4caf50; /* Green fill color */
    border-radius: 10px;
    position: relative;
  }
  
  .progress-pill {
    width: 18px;
    height: 18px;
    background-color: #D0004B;
    border: 3px solid white; /* Green border to match the progress color */
    border-radius: 50%;
    position: absolute;
    top: 50%;
    /* left: -6px; */
    right: 0; /* Position it at the end of the green progress fill */
    transform: translate(50%, -50%); /* Center the pill vertically and horizontally */
    z-index: 100;
  }
  
  .progress-bar-label {
    position: absolute;
    top: -25px;
    left: 8%; /* Match the width of the progress fill */
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 12px;
  }
  
  .progress-values {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .progress-info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .info-item {
    text-align: center;
  }
  
  .info-item span {
    display: block;
    color: #888;
    font-size: 12px;
  }
  
  .info-item strong {
    display: block;
    font-size: 16px;
  }
  