.fund-me-card {
     background: linear-gradient(107.91deg, #FF7AAA 7.37%, #D0004B 95.19%);
      /* Pink gradient */
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    max-width: 220px;
    margin: auto;
  }
  
  .fund-me-text {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
  }

  .fund-me-text-hidden {
    display: none;
  }
  
  .fund-me-button {
    display: inline-flex;
    align-items: center;
    background-color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    color: #d53f8c;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    gap: 10px;
    width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .fund-me-button:hover {
    background-color: #f7f7f7;
  }
  
  .fund-me-icon {
    margin-right: 8px;
  }
  